/* Nunito Light (300) */
@font-face {
  font-family: 'Nunito';
  src: url('/fonts/nunito-v26-latin-300.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

/* Nunito Regular (400) */
@font-face {
  font-family: 'Nunito';
  src: url('/fonts/nunito-v26-latin-400.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

/* Nunito Medium (500) */
@font-face {
  font-family: 'Nunito';
  src: url('/fonts/nunito-v26-latin-500.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

/* Nunito Semi-Bold (600) */
@font-face {
  font-family: 'Nunito';
  src: url('/fonts/nunito-v26-latin-600.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

/* Nunito Bold (700) */
@font-face {
  font-family: 'Nunito';
  src: url('/fonts/nunito-v26-latin-700.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

/* Nunito Extra-Bold (800) */
@font-face {
  font-family: 'Nunito';
  src: url('/fonts/nunito-v26-latin-800.woff2') format('woff2');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Hide arrows from number input */
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
  /* Firefox */
}

.map-container {
  width: 100vw;
  height: 100vh;
}

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;

    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;

    --ring: 215 20.2% 65.1%;

    --radius: 0.5rem;


  }

  .dark {
    --background: 222.2 84% 4.9%;
    --foreground: 210 40% 98%;

    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;

    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;

    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;

    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;

    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 11.2%;

    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;

    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;

    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 0 85.7% 97.3%;

    --ring: 217.2 32.6% 17.5%;
  }
}

::-webkit-scrollbar {
  /* display: none; */
}

/* Styling the container that wraps the scrollable content */
.scroll-container {
  overflow: auto;
  scrollbar-width: thin;
  scrollbar-color: #333 red;
}

/* Styling the scrollbar thumb */
.scroll-container::-webkit-scrollbar-thumb {
  background-color: red;
}

/* Styling the scrollbar track */
.scroll-container::-webkit-scrollbar-track {
  background-color: blue;
}

@layer base {
  * {
    @apply border-border box-border;
  }

  .mapContainer {
    @apply w-full md:h-[400px] max-w-[400px] h-[300px]
  }
}

@layer components {
  .custom-bullet p::before {
    @apply text-lg leading-none align-middle mr-2;
    content: '• ';
  }
}

@layer utilities {
  .shake-animation {
    @apply animate-shake;
  }
}

/* This CSS goes in your global stylesheet */
.scrollable-x {
  overflow-x: auto;
  scrollbar-width: none;
  /* For Firefox */
  -ms-overflow-style: none;
  /* For Internet Explorer and Edge */
}

.scrollable-x::-webkit-scrollbar {
  display: none;
  /* For Chrome, Safari, and Opera */
}

@keyframes showScrollHint {

  0%,
  100% {
    transform: translateX(0px);
  }

  50% {
    transform: translateX(-20px);
    /* Adjust the value to suit your design */
  }
}

.scroll-hint-animation {
  animation: showScrollHint .75s ease-in-out;
}

.upward-shadow {
  box-shadow: 0 -3px 10px -1px rgba(0, 0, 0, 0.04),
    0 -1px 10px -1px rgba(0, 0, 0, 0.04);
}

.shadow-top-bottom {
  box-shadow: 0 -5px 5px -5px rgba(0, 0, 0, 0.1), 0 5px 5px -5px rgba(0, 0, 0, 0.1);
}

.loader {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  position: relative;
  animation: rotate 1s linear infinite
}

.loader::before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  inset: 0px;
  border-radius: 50%;
  border: 5px solid #e80071;
  animation: prixClipFix 2s linear infinite;
}

.animate-shake {
  animation: shake 1s ease-in-out;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg)
  }
}

@keyframes shake {

  0%,
  100% {
    transform: translateX(0);
  }

  12.5% {
    transform: translateX(-5px);
  }

  25% {
    transform: translateX(5px);
  }

  37.5% {
    transform: translateX(-5px);
  }

  50% {
    transform: translateX(5px);
  }

  67.5% {
    transform: translateX(-5px);
  }

  75% {
    transform: translateX(5px);
  }

  87.5% {
    transform: translateX(-5px);
  }
}

@keyframes prixClipFix {
  0% {
    clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0)
  }

  25% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0)
  }

  50% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%)
  }

  75% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%)
  }

  100% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 0)
  }
}

.bg-pink-radial {
  background: radial-gradient(ellipse at top center, rgba(255, 105, 180, 0) 72%, rgba(232, 0, 113, 1) 72%);
  background-size: 100% 90%;
  background-position: center top;
}

.fade-in {
  animation: fadeIn 2.5s ease-in-out forwards;
}

.fade-out {
  animation: fadeOut 2.5s ease-in-out forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}